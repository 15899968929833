<template>
  <div class="bg-white relative">
    <el-tabs
      v-model="current"
      type="card"
      ref="tabs"
      @tab-remove="deleteItem"
      :before-leave="beforeLeave"
    >
      <el-tab-pane
        v-for="(audience, index) in value"
        :key="'label_' + index"
        :name="index + ''"
        closable
      >
        <span slot="label">
          <i
            class="el-icon-warning text-red-600"
            v-if="errList.includes(index)"
          ></i>
          受众{{ index + 1 }}
          <!-- <i
            class="el-icon-circle-close ml-2 text-zinc-800 hover:text-primary"
            @click="deleteItem(index)"
          ></i> -->
        </span>

        <div
          class="m-6 pr-24"
          :key="index"
        >
          <el-form
            :model="audience"
            label-width="120px"
            :rules="rules"
            ref="form"
          >
            <el-form-item
              label="地区"
              prop="geos"
            >
              <div class="flex">
                <el-select
                  v-model="audience.geos"
                  class="flex-1"
                  multiple
                  filterable
                  default-first-option
                  @change="() => onChangeGeos(audience.geos, index)"
                >
                  <el-option-group
                    v-for="country in countryList"
                    :key="country.code"
                    :label="country.name"
                  >
                    <el-option
                      v-for="item in country.children"
                      :key="item.code"
                      :label="item.name"
                      :value="item.myCode"
                    >
                    </el-option>
                  </el-option-group>
                </el-select>
                <el-button
                  type="text"
                  @click="autoFillGeos(index)"
                  class="!ml-2"
                  >自动填充</el-button
                >
              </div>
            </el-form-item>

            <el-form-item
              label="年龄"
              required
            >
              <div class="flex">
                <el-form-item prop="minAge">
                  <el-select
                    v-model="audience.minAge"
                    @change="(val) => ageChange('minAge', val, audience)"
                    clearable
                  >
                    <el-option
                      v-for="age in minAgeList"
                      :label="age.name"
                      :key="age.name"
                      :value="age.value"
                    ></el-option>
                  </el-select>
                </el-form-item>

                <div class="mx-6">~</div>
                <el-form-item prop="maxAge">
                  <el-select
                    v-model="audience.maxAge"
                    @change="(val) => ageChange('maxAge', val, audience)"
                    clearable
                  >
                    <el-option
                      v-for="age in ageList"
                      :label="age.name"
                      :key="age.name"
                      :value="age.value"
                    ></el-option
                  ></el-select>
                </el-form-item>
              </div>
            </el-form-item>

            <el-form-item
              label="性别"
              prop="gender"
            >
              <el-radio-group v-model="audience.gender">
                <el-radio-button
                  v-for="gender in enumConstants.genders"
                  :key="gender.key"
                  :label="gender.key"
                  >{{ gender.value }}</el-radio-button
                >
              </el-radio-group>
            </el-form-item>

            <el-form-item label="语言">
              <el-select
                multiple
                class="w-full"
                filterable
                default-first-option
                v-model="audience.languages"
              >
                <el-option
                  v-for="languages in enumConstants.languages"
                  :label="languages.value"
                  :value="languages.key"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="预设受众">
              <predefinedAudiences
                v-model="audience.interests"
                :list="predefinedAudienceList[index]"
                :interestLabelQueryList="interestLabelQueryList[index] || []"
                :interestLabelSaveAPI="interestLabelSaveAPI"
                v-loading="predefinedAudienceLoading[index]"
                :countryCodes="audience.geos"
                :adAccountId="data.adAccountId"
              ></predefinedAudiences>
            </el-form-item>

            <el-form-item label="自定义受众">
              <customAudience
                v-model="audience.segments"
                :list="customAudienceList"
                v-loading="customAudienceLoading"
              ></customAudience>
            </el-form-item>
            <el-form-item label="自动扩展受众">
              <el-checkbox v-model="audience.autoExpansionOptions">自动扩展受众</el-checkbox>
            </el-form-item>
            <el-form-item
              prop="audienceSize"
              label="受众范围"
            >
              <audienceSize
                v-model="audience.audienceSize"
                :audience="JSON.parse(JSON.stringify(audience))"
                :data="data"
                @input="onChangeAudienceSize(index)"
              ></audienceSize>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
      <el-tab-pane name="add">
        <div
          slot="label"
          @click.stop="addItem"
        >
          <i class="el-icon-circle-plus-outline mr-1" />添加受众
        </div>
      </el-tab-pane>
    </el-tabs>
    <div class="absolute z-50 right-6 top-0 h-10 leading-10">
      <div
        slot="label"
        @click.stop="copyItem"
        class="hover:text-primary text-sm leading-10"
      >
        <i class="el-icon-copy-document mr-1" />复制受众
      </div>
    </div>
  </div>
</template>
<script>
import { customAudienceQuery, interestLabelSave } from '@/api/addGoods';
import { queryTreeCountry, queryInterestByCountry } from '@/api/snapchat/create';
import { minAgeList, ageList, getAudienceDefault } from '../../index.js';
import predefinedAudiences from '@/views/snapchat/components/predefinedAudiences';
import customAudience from '@/views/snapchat/components/customAudience/index.vue';
import audienceSize from './audienceSize/index.vue';
import _ from 'lodash';
import { rules } from './index.js';
export default {
  components: { predefinedAudiences, customAudience, audienceSize },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    data: Object,
    enumConstants: Object,
  },
  data() {
    this.getPredefinedAudienceListDebounce = _.debounce(this.getPredefinedAudienceList, 300);
    return {
      // formData: [],
      current: '0', // 当前受众下标
      ageList,
      minAgeList,
      interestLabelSaveAPI: interestLabelSave,
      predefinedAudienceList: [], // 预设受众列表
      predefinedAudienceLoading: [], //预设受众接口
      customAudienceList: [], // 自定义受众
      customAudienceLoading: false, // 自定义受众
      countryList: [], // 地区列表
      interestLabelQueryList: [], // 常用
      rules,
      errList: [], // 验证不通过列表
    };
  },
  methods: {
    addItem() {
      this.value.push(getAudienceDefault());
      this.$emit('input', this.value);
    },
    copyItem() {
      let curreny = this.value[this.current];
      this.value.push(JSON.parse(JSON.stringify(curreny)));
      this.$set(this.predefinedAudienceList, this.value.length - 1, this.predefinedAudienceList[this.current]);
      this.$emit('input', this.value);
    },
    deleteItem(index) {
      if (this.value.length == 1) {
        this.$message({
          type: 'warning',
          message: '至少保留一个受众',
        });
        return;
      }
      this.value.splice(index, 1);
      if (this.current >= this.value.length) {
        this.current = `${this.value.length - 1}`;
      }
      this.$emit('input', this.value);
    },
    beforeLeave(active, oldActive) {
      console.log(active, oldActive);
      if (active == 'add') {
        this.addItem();
        return false;
      }
    },
    // 获取预设受众列表
    getPredefinedAudienceList(val, index) {
      // this.predefinedAudienceLoading = true;
      if (!val || !val.length) {
        return;
      }
      this.$set(this.predefinedAudienceLoading, index, true);
      queryInterestByCountry(val)
        .then((res) => {
          if (res.code == 0) {
            this.$set(this.predefinedAudienceList, index, res.data);
          }
        })
        .finally(() => {
          this.$set(this.predefinedAudienceLoading, index, false);

          // this.predefinedAudienceLoading = false;
        });
    },
    // 获取自定义受众列表
    getCustomAudienceList(index) {
      let params = {
        accountId: this.data.adAccountId,
      };
      this.customAudienceLoading = true;
      customAudienceQuery(params)
        .then((res) => {
          if (res.code == 0) {
            this.customAudienceList = res.data.map((item) => ({ ...item, name: item.key }));
          }
        })
        .finally(() => {
          this.customAudienceLoading = false;
        });
    },
    // 自动填充
    autoFillGeos(index) {
      let val = [this.data.promotionInfo.site.countryCode];
      this.$set(this.value[index], 'geos', val);
      this.onChangeGeos(val, index);
    },
    // 国家
    getCountryList() {
      queryTreeCountry().then((res) => {
        this.countryList = res.data;
      });
    },
    // 修改地区
    onChangeGeos(val, index) {
      this.getPredefinedAudienceListDebounce(val, index);
    },
    // 对外验证
    validate() {
      let list = [];
      this.errList = [];
      this.$refs.form.map((form, index) => {
        list.push(form.validate().catch(() => index));
      });
      return new Promise((resolve, reject) => {
        Promise.allSettled(list).then((res) => {
          res.map((item) => {
            if (typeof item.value == 'number') {
              this.errList.push(item.value);
              reject();
            }
          });
          resolve();
        });
      });
    },
    clearValidate() {
      if (!this.$refs.form) return;
      let list = [];
      this.errList = [];
      this.$refs.form.map((form, index) => {
        list.push(form.clearValidate());
      });
      return Promise.all(list);
    },
    onChangeAudienceSize(index) {
      if (this.$refs.form && this.$refs.form[index]) {
        this.$refs.form[index].validate().then(() => {
          this.errList = _.without(this.errList, index);
        });
      }
    },
    ageChange(key, val, audience) {
      switch (key) {
        case 'minAge':
          if (val > audience.maxAge) {
            this.$set(audience, 'maxAge', val);
          }
          break;
        case 'maxAge':
          if (val < audience.minAge) {
            this.$set(audience, 'minAge', val);
          }
      }
    },
  },
  created() {
    this.getCountryList();
  },
  watch: {
    // 监听账户改变，需要自定义受众列表
    'data.adAccountId': {
      handler(val, oldVal) {
        if (val != oldVal && val) {
          this.getCustomAudienceList();
        }
      },
    },
  },
};
</script>
